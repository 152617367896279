<template>
  <el-row class="kf-row">
    <el-card class="box-card" :body-style="{ padding: '10px 20px' }">
      <!-- header -->
      <div slot="header" class="clearfix">
        <span>筛选条件</span>
        <el-popconfirm
          confirm-button-text="好的"
          cancel-button-text="不用了"
          icon="el-icon-info"
          icon-color="red"
          title="本操作将输入恢复至默认值，是否确认？"
          @confirm="onClearForm"
        >
          <el-button
            :style="{ float: 'right', marginLeft: '20px' }"
            type="danger"
            size="small"
            slot="reference"
            >一键清空</el-button
          >
        </el-popconfirm>
      </div>

      <!-- 基本筛选条件 -->
      <el-row class="kf-main__row" :style="{ lineHeight: '38px' }">
        <el-col :span="12">
          <el-row>
            <el-col :span="4" :xl="{ span: 2 }">
              <el-tooltip
                effect="dark"
                content="开启后，结果不包含重复的条目"
                placement="top"
              >
                <span class="kf-main__cate">去重</span>
              </el-tooltip>
            </el-col>
            <el-col :span="20" :xl="{ span: 22 }">
              <el-switch
                v-model="kwRepeat"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col :span="2" :xl="{ span: 1 }">词长</el-col>
            <el-col :span="22" :xl="{ span: 23 }">
              <el-slider v-model="kwLength" show-input></el-slider>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <!-- 筛选词 -->
      <filter-list
        title="包含(或)"
        desc="结果包含至少一个右侧指定的关键字"
        :list="kwInclude"
        listName="kwInclude"
        v-on="$listeners"
      />
      <filter-list
        title="不包含"
        desc="结果不包含右侧指定关键字，优先级最高"
        :list="kwExclude"
        listName="kwExclude"
        v-on="$listeners"
      />
      <filter-list
        title="包含(且)"
        desc="在包含(或)的基础上，结果还将包含所有右侧关键字"
        :list="kwIncludeAnd"
        listName="kwIncludeAnd"
        v-on="$listeners"
      />
    </el-card>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterList from './FilterList'

export default {
  components: {
    FilterList
  },
  computed: {
    ...mapGetters(['kwExclude', 'kwInclude', 'kwIncludeAnd']),
    kwRepeat: {
      get() {
        return this.$store.getters.kwRepeat
      },
      set(value) {
        this.$store.dispatch('keywordFilter/updateKwRepeat', value)
      }
    },
    kwLength: {
      get() {
        return this.$store.getters.kwLength
      },
      set(value) {
        this.$store.dispatch('keywordFilter/updateKwLength', value)
      }
    }
  },
  methods: {
    onClearForm() {
      this.$store.dispatch('keywordFilter/resetFilter')
    }
  }
}
</script>
