<template>
  <div class="kf">
    <filter-card :showEditor.sync="showEditor" />
    <keyword-editor v-if="showEditor" :showEditor.sync="showEditor" />

    <el-row class="kf-row" :gutter="40">
      <el-col :span="12">
        <input-card />
      </el-col>
      <el-col :span="12">
        <output-card :result="result" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterCard from './components/filter-card/'
import OutputCard from './components/OutputCard'
import InputCard from './components/InputCard'
import KeywordEditor from './components/keyword-editor'

export default {
  name: 'keyword-filter',
  components: {
    FilterCard,
    KeywordEditor,
    InputCard,
    OutputCard
  },
  data() {
    return {
      showEditor: false
    }
  },
  computed: {
    ...mapGetters([
      'kwLength',
      'kwRepeat',
      'kwInclude',
      'kwIncludeAnd',
      'kwExclude',
      'inputString'
    ]),
    filterString() {
      return this.inputString
        .trim()
        .split('\n')
        .filter(v => v.length)
    },
    result() {
      let res = [].concat(this.filterString)
      res = this.filterRepeat(res)
      res = this.filterLength(res)
      res = this.filterExclude(res)
      res = this.filterIncludeAnd(res)
      res = this.filterInclude(res)
      return res
    }
  },
  methods: {
    filterRepeat(res) {
      if (!this.kwRepeat) return res
      // Todo: 删除包含字符相同的条目
      return [...new Set(res)]
    },
    filterLength(res) {
      return res.filter(v => v.length <= this.kwLength)
    },
    filterExclude(res) {
      if (this.kwExclude.length === 0) return res
      return res.filter(v => {
        for (let i = 0, len = this.kwExclude.length; i < len; i++) {
          if (new RegExp(this.kwExclude[i], 'i').test(v)) {
            return false
          }
        }
        return true
      })
    },
    filterIncludeAnd(res) {
      if (this.kwIncludeAnd.length === 0) return res
      return res.filter(v => {
        for (let i = 0, len = this.kwIncludeAnd.length; i < len; i++) {
          if (!new RegExp(this.kwIncludeAnd[i], 'i').test(v)) {
            return false
          }
        }
        return true
      })
    },
    filterInclude(res) {
      if (this.kwInclude.length === 0) return res
      return res.filter(v => {
        for (let i = 0, len = this.kwInclude.length; i < len; i++) {
          if (new RegExp(this.kwInclude[i], 'i').test(v)) {
            return true
          }
        }
        return false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.kf {
  box-sizing: border-box;
  padding: 0 0.2rem 0.2rem;
  .kf-row {
    box-sizing: border-box;
    margin-top: 0.1rem;
  }
}
</style>
