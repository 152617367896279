<template>
  <el-dialog
    class="kw-editor"
    width="80%"
    :title="activeTitle"
    :visible.sync="showEditor"
    :before-close="onClose"
  >
    <!-- 一键添加 -->
    <el-tabs v-model="activeTab">
      <el-tab-pane label="一键添加" name="add">
        <div class="kw-editor__content">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="当前已使用" :name="0">
              <el-button
                v-for="item in kwTemp"
                :key="item"
                type="primary"
                size="mini"
                >{{ item }}</el-button
              >
            </el-collapse-item>
            <el-collapse-item
              v-for="(cate, index) in activeCateTemp.list"
              :key="cate.title + index"
              :title="cate.title"
              :name="index + 1"
            >
              <el-checkbox-group v-model="kwTemp">
                <el-checkbox
                  v-for="item in cate.kws"
                  :key="item"
                  :label="item"
                  border
                  size="mini"
                />
              </el-checkbox-group>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-tab-pane>

      <!-- 管理分类 -->
      <el-tab-pane label="管理分类" name="config" lazy>
        <cate-item
          v-for="(item, index) in activeCateTemp.list"
          :key="item.title + index"
          :title.sync="item.title"
          @remove="onRemove(index)"
          @rename="onRename"
          control
        >
          <cate-content
            :cate="item"
            @delete="onDelete"
            @add="onAddItem"
          ></cate-content>
        </cate-item>
        <el-button
          :style="{ width: '100%' }"
          icon="el-icon-plus"
          size="small"
          @click="onAddCate"
          >添加分类</el-button
        >
      </el-tab-pane>
    </el-tabs>

    <!-- slot -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import CateItem from './CateItem'
import CateContent from './CateContent'
import { mapGetters } from 'vuex'
import { upperFirst } from '@/utils'

export default {
  name: 'keyword-editor',
  components: {
    CateItem,
    CateContent
  },
  props: {
    showEditor: { type: Boolean }
  },
  data() {
    return {
      activeTab: 'add',
      activeNames: [0, 1, 2, 3, 4],
      kwTemp: [], // 勾选时显示
      activeCateTemp: [] // 配合 activeTitle 写入 storage
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    ...mapGetters(['filterType', 'kwInclude', 'kwIncludeAnd', 'kwExclude']),
    // dialog 表头
    activeTitle() {
      return this.filterType === 'kwInclude'
        ? '包含'
        : this.filterType === 'kwIncludeAnd'
        ? '包含(且)'
        : '不包含'
    },
    // storage 存储的所有数据
    kwCate() {
      return this.getItem('kwCate') || {}
    },
    // 当前激活的分类配置
    activeCate: {
      // like that: kwCate.kwInclude
      get() {
        return this.kwCate[this.filterType] || this.createKwCateItem()
      },
      set(value) {
        console.log('update storage:', value)
        this.kwCate[this.filterType] = value
        this.setItem('kwCate', this.kwCate) // 提交 storage
      }
    },
    activeList() {
      return this.filterType === 'kwInclude'
        ? this.kwInclude
        : this.filterType === 'kwIncludeAnd'
        ? this.kwIncludeAnd || []
        : this.kwExclude || []
    }
  },
  methods: {
    initData() {
      this.activeCateTemp = this.activeCate
      this.kwTemp = this.activeList
      // this.filterCheckList()
    },
    createKwCateItem() {
      return {
        name: this.filterType,
        list: []
      }
    },
    /* 处理事件：一键添加 */
    // 确认添加
    onConfirm() {
      this.$store.dispatch(
        `keywordFilter/update${upperFirst(this.filterType)}`,
        [...new Set(this.kwTemp)]
      )
      this.$emit('update:showEditor', false)
    },
    // 取消添加
    onClose() {
      this.$emit('update:showEditor', false)
    },
    // 勾选已选筛选词
    filterCheckList() {
      for (const item of this.activeCateTemp) {
        item.checkList = []
        for (const temp of this.activeList) {
          if (item.kws.includes(temp)) {
            item.checkList.push(temp)
          }
        }
      }
    },

    /* 处理事件：管理配置 */
    // 添加关键词
    onAddItem({ cate, text }) {
      const list = text
        .trim()
        .replace(/\s+/g, ' ')
        .split(' ')

      cate.kws.push(...list)
      cate.kws = [...new Set(cate.kws)]
      this.activeCate = this.activeCateTemp
    },
    // 删除关键词
    onDelete({ cate, index }) {
      cate.kws.splice(index, 1)
      this.activeCate = this.activeCateTemp
    },
    // 修改分类名
    onRename() {
      this.activeCate = this.activeCateTemp
    },
    // 删除分类
    onRemove(index) {
      this.activeCateTemp.list.splice(index, 1)
      this.activeCate = this.activeCateTemp
    },
    // 添加新分类
    onAddCate() {
      this.$prompt('请输入分类名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /[^\s]+/,
        inputErrorMessage: '名字不能为空！'
      })
        .then(({ value }) => {
          // todo: 添加更严格的验证信息
          this.activeCateTemp.list.push({
            title: value.trim(),
            kws: []
          })
          this.activeCate = this.activeCateTemp
          this.$message({
            type: 'success',
            message: '成功添加分类！'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
    },

    // other
    handleChange(val) {
      console.log(val)
    },

    // storage
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    getItem(key) {
      const ret = localStorage.getItem(key)
      return ret ? JSON.parse(ret) : undefined
    },
    hasItem(key) {
      return localStorage.hasItem(key)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-dialog {
  margin-top: 10vh !important;
}

/deep/ .el-dialog__body {
  padding: 0 20px !important;
}

.kw-editor {
  &__content {
    overflow: auto;
    max-height: 60vh;
  }
}
</style>
