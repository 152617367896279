<template>
  <div>
    <base-badge
      :style="{ marginRight: '10px' }"
      v-for="(item, index) in cate.kws"
      :key="item"
      @delete="$emit('delete', { cate, index })"
      >{{ item }}</base-badge
    >
    <el-tooltip content="添加关键词" placement="top">
      <el-button
        v-if="!showInput"
        icon="el-icon-plus"
        size="mini"
        circle
        @click="onShowInput"
      ></el-button>
      <el-input
        v-else
        :style="{ maxWidth: '2rem' }"
        ref="input"
        v-model="input"
        type="text"
        clearable
        placeholder="可添加多个，使用空格分隔"
        size="mini"
        @blur="showInput = false"
        @keyup.enter.native="onAdd(cate)"
      ></el-input>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    cate: { type: Object, require: true }
  },
  data() {
    return {
      showInput: false,
      input: ''
    }
  },
  methods: {
    onShowInput() {
      this.showInput = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    onAdd(cate) {
      this.$emit('add', { cate, text: this.input })
      this.showInput = false
      this.input = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
