<template>
  <div class="ct">
    <div class="ct-header" @click="toggleCollapse">
      <div class="ct-header__name">{{ title }}</div>
      <div class="ct-header__detail"></div>
      <div class="ct-header__ctrl" v-show="control">
        <el-tooltip content="重命名分类" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
            @click.stop="onRename"
          ></el-button>
        </el-tooltip>

        <el-tooltip content="删除分类" placement="top">
          <el-button
            icon="el-icon-delete"
            type="danger"
            circle
            size="mini"
            @click.stop="onRemove"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="ct-header__arrow" @click.stop="toggleCollapse">
        <i
          class="icon el-icon-arrow-down"
          :class="{ 'is-collapse': collapse }"
        ></i>
      </div>
    </div>

    <div class="ct-content__wrapper" ref="wrapper">
      <div class="ct-content" ref="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '通用' },
    control: { type: Boolean, default: false }
  },
  data() {
    return {
      collapse: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.collapse) {
        this.$refs.wrapper.style.height = this.height + 'px'
      }
    })
  },
  computed: {
    height() {
      return this.$refs.content.getBoundingClientRect().height
    }
  },
  methods: {
    onRename() {
      console.log('edit')
      this.$prompt('重命名为：', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /[^\s]+/,
        inputErrorMessage: '名字不能为空！'
      })
        .then(({ value }) => {
          // console.log(value)
          this.$emit('update:title', value.trim())
          this.$emit('rename')
          this.$message({
            type: 'success',
            message: '操作成功！'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    onRemove() {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$emit('remove')
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    toggleCollapse() {
      this.collapse = !this.collapse
    }
  },
  watch: {
    collapse(newVal) {
      if (!newVal) {
        // 展开
        this.$refs.wrapper.style.height = this.height + 'px'
      } else {
        this.$refs.wrapper.style.height = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ct {
  font-size: 14px;
  &-header {
    display: flex;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #eee;
    cursor: pointer;
    /* background-color: #efefef; */
    &:hover {
      background-color: #eee;
    }
    &__name {
      font-weight: bold;
    }
    &__detail {
      flex: 1;
    }
    &__ctrl {
    }
    &__arrow {
      width: 50px;
      text-align: center;
      cursor: pointer;
      .icon {
        transition: transform 0.1s;
        &.is-collapse {
          transform: rotateZ(-90deg);
        }
      }
    }
  }
  &-content {
    /* min-height: 200px; */
    &__wrapper {
      overflow: hidden;
      transition: all 0.15s;
    }
    padding: 20px 0;
  }
}
</style>
