<template>
  <el-card class="kf-card" :body-style="{ padding: 0 }">
    <div slot="header" class="clearfix">
      <span>输入：{{ count }} 行</span>
      <el-button
        type="danger"
        size="mini"
        :style="{ float: 'right' }"
        @click="onClear"
        >清空</el-button
      >
    </div>
    <div class="kf-card__wrapper">
      <el-input
        ref="textarea"
        type="textarea"
        autosize
        resize="none"
        placeholder="请输入内容"
        v-model="inputString"
      >
      </el-input>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'input-card',
  computed: {
    inputString: {
      get() {
        return this.$store.getters.inputString
      },
      set(value) {
        this.$store.dispatch('keywordFilter/updateInputString', value)
      }
    },
    count() {
      return this.inputString.length > 0
        ? this.inputString.split('\n').length
        : 0
    }
  },
  mounted() {
    /* HTMLElement.prototype.pressKey = function(code) {
      console.log(code)
      const evt = document.createEvent('UIEvents')
      evt.keyCode = code
      evt.initEvent('keydown', true, true)
      this.dispatchEvent(evt)
    } */
  },
  methods: {
    onClear() {
      /* const textarea = this.$refs['textarea']
      textarea.select()
      textarea.focus()
      textarea.$el.pressKey(8) */
      this.inputString = ''
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__header {
  padding: 12px 20px;
  overflow: auto;
}

.kf-card {
  &__wrapper {
    overflow: auto;
    height: 55vh;
  }
}
</style>
