<template>
  <el-row class="kf-main__row">
    <el-col :span="2" :xl="{ span: 1 }">
      <el-tooltip effect="dark" :content="desc" placement="top">
        <span class="kf-main__cate">{{ title }}</span>
      </el-tooltip>
    </el-col>
    <el-col :span="22" :xl="{ span: 23 }">
      <div class="cate-list">
        <base-badge
          :style="{ marginRight: '10px' }"
          v-for="(item, index) in list"
          :key="item"
          @delete="onDelete(index)"
          >{{ item }}</base-badge
        >
        <el-input
          v-show="showInput"
          :style="{ maxWidth: '2rem', marginRight: '10px' }"
          ref="input"
          v-model="input"
          size="mini"
          clearable
          placeholder="可输入多个，使用空格分隔"
          @blur="showInput = false"
          @keyup.enter.native="onAdd"
        ></el-input>
        <btn-group
          @add="onShowInput"
          @edit="onShowEditor"
          @clear="onClear"
        ></btn-group>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import BtnGroup from '../BtnGroup'

export default {
  components: {
    BtnGroup
  },
  props: {
    title: { type: String, default: '' },
    desc: { type: String, default: '' },
    list: { type: Array, require: true },
    listName: { type: String, require: true }
  },
  data() {
    return {
      input: '',
      showInput: false
    }
  },
  methods: {
    upperFirst(str) {
      if (!str) return str
      return str.replace(str[0], str[0].toUpperCase())
    },
    onAdd() {
      const value = this.input
        .trim()
        .split(' ')
        .filter(Boolean)
        .map(v => v.trim())

      this.$store.dispatch(
        `keywordFilter/add${this.upperFirst(this.listName)}Item`,
        value
      )
      this.input = ''
      this.showInput = false
    },
    onDelete(index) {
      this.$store.dispatch(
        `keywordFilter/remove${this.upperFirst(this.listName)}Item`,
        index
      )
    },
    onShowInput() {
      this.showInput = true
      // fix: 需要下一个 tick 才能获取节点
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    onShowEditor() {
      this.$emit('update:showEditor', true)
      this.$store.dispatch('keywordFilter/updateFilterType', this.listName)
    },
    onClear() {
      this.$store.dispatch(
        `keywordFilter/clear${this.upperFirst(this.listName)}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.kf-main__row {
  display: flex;
  margin: 0.1rem 0;
  .cate-list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
