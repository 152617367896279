<template>
  <el-card class="kf-card">
    <div slot="header" class="clearfix">
      <span>输出：{{ result.length }} 行</span>
      <div :style="{ float: 'right' }">
        <el-input-number
          v-model="numStart"
          :style="{ maxWidth: '1rem', margin: '0 10px' }"
          controls-position="right"
          :min="count ? 1 : 0"
          :max="numEnd"
          size="mini"
        ></el-input-number>
        <span>～</span>
        <el-input-number
          v-model="numEnd"
          :style="{ maxWidth: '1rem', margin: '0 10px' }"
          controls-position="right"
          :min="count ? 1 : 0"
          :max="count"
          size="mini"
        ></el-input-number>
        <el-button type="primary" size="mini" @click="onCopy">复制</el-button>
        <el-button type="primary" size="mini" @click="opPaste">剪切</el-button>
      </div>
    </div>
    <div class="kf-card__wrapper">
      <div class="kf-card__item">
        <div class="item-left">
          <div class="item" v-for="index in count" :key="index">
            {{ index }}
          </div>
        </div>
        <div class="item-content">
          <div
            class="item"
            v-for="(item, index) of resultTemp"
            :key="item + index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'output-card',
  props: {
    result: { type: Array, require: true }
  },
  data() {
    return {
      numStart: 1,
      numEnd: 1,
      resultTemp: []
    }
  },
  computed: {
    count() {
      return this.resultTemp.length
    }
  },
  mounted() {
    this.resultTemp = this.result
  },
  methods: {
    onCopy() {
      if (this.numStart > this.numEnd) return this.rangeError()
      navigator.clipboard
        .writeText(
          this.resultTemp.slice(this.numStart - 1, this.numEnd).join('\n')
        )
        .then(() => {
          this.$notify({
            title: '复制成功！',
            type: 'success'
          })
        })
        .catch(() => {
          this.$notify({
            title: '复制失败！',
            type: 'error',
            message: '未知错误'
          })
        })
    },
    opPaste() {
      if (this.numStart > this.numEnd) return this.rangeError()
      navigator.clipboard
        .writeText(
          this.resultTemp
            .splice(this.numStart - 1, this.numEnd - this.numStart + 1)
            .join('\n')
        )
        .then(() => {
          this.$notify({
            title: '剪切成功！',
            type: 'success'
          })
        })
        .catch(() => {
          this.$notify({
            title: '剪切失败！',
            type: 'error',
            message: '未知错误'
          })
        })
    },
    rangeError() {
      this.$notify({
        title: '操作失败',
        type: 'error',
        message: '请检查起始区间！'
      })
    }
  },
  watch: {
    result() {
      this.resultTemp = this.result
    },
    count(newVal) {
      this.numEnd = newVal >= 200 ? 200 : newVal
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__header {
  padding: 12px 20px;
  overflow: auto;
}
/deep/ .el-card__body {
  padding: 0;
}

.kf-card {
  box-sizing: border-box;
  overflow-y: auto;
  &__wrapper {
    overflow: auto;
    height: 55vh;
  }
  &__item {
    display: flex;
    font-size: 0.16rem;

    .item-left {
      display: flex;
      flex-direction: column;
      background-color: #e7e7e7;
      .item {
        width: 0.5rem;
        height: 0.3rem;
        line-height: 0.3rem;
        text-align: center;
      }
    }
    .item-content {
      flex: 1;
      .item {
        padding-left: 20px;
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 14px;
        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }
}
</style>
